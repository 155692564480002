.profileTabSelected {
    width: 205px;
    font-size: 14px;
    text-transform: uppercase !important;
    color: black !important;
    font-weight: 500 !important;
}
  
.profileTabUnselected {
    width: 205px;
    font-size: 14px;
    text-transform: uppercase !important;
    color: grey !important;
    font-weight: 100 !important;
}

.companyProfile {
    margin: 0px;
    white-space: normal;
    font-size: 21px !important;
    line-height: 1.6;
    color: #CC0000;
    font-family: "Graphik, sans-serif";
    font-weight: 400;
}

.modalBox {
    overflow: scroll;
}

.subsidiaries {
    height: 50vh;
    width: 100%;
    margin-bottom: 20px;
}