.breadcrumbSelected {
    color: #cc0000;
    margin: 0px ;
    white-space: normal;
}

.breadcrumbUnselected {
    color: #666666;
    margin: 0px ;
    white-space: normal;
}