.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.lastSelectedFilter {
  width: "50px";
  border: 2px solid black;
  padding: 5px;
  border-radius: 25px;
  color: "white";
  background-color: red;
}

.normalFilter {
  width: "50px";
  border: 2px solid black;
  padding: 5px;
  border-radius: 25px;
  color: "black";
  background-color: grey;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#rangeFilterSection {
  width: 200px;
  height: 200px;
  position: absolute;
  z-index: 100;
}

.selectDropdown {
  border-width: 0 0 1px;
  background-color: #fff;
  border-radius: 0;
}

.pointer {
  cursor: pointer;
  outline: none;
}

.textUnderline {
  text-decoration: underline;
}

.rangeDropdown:hover {
  border-color: rgb(102, 102, 102);
}

.rangeDropdown:focus {
  border-color: rgb(102, 102, 102);
}

.autocomplete-option:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.css-14q7rqj {
  border-bottom: 2px solid #ddd;
}

#min,
#max {
  min-width: 20px;
  width: 20px;
}

.headerOptions {
  display: flex;
  margin-top: 5px;
  width: 95%;
  justify-content: space-between;
}

.supportOptions {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  color: #cc0000;
}

.helpLink {
  text-decoration: none;
  color: #cc0000;
}

.MenuIcon {
  font-size: 46px;
  margin: 1px 10px 0 10px;
  color: #666;
}

.hmCardIcon {
  margin: 0 auto;
  font-size: 67px;
  color: lightgray;
}

.centerAlign {
  margin: 0 auto;
}

.MyLoader_overlay {
  z-index: 1300;
  opacity: 999;
}

.addIcon {
  color: #c00;
  margin-right: "-8px";
  font-size: 22px;
  cursor: pointer;
}

.removeIcon {
  font-size: 20px;
  cursor: pointer;
}

.arrowIcon {
  font-size: 30px;
  margin-top: 5px;
  color: #666;
}

.rangeCard {
  padding: 0 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.table-cell-trucate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.percentageCellStyle {
  margin: auto;
  padding: 3px 10px;
  line-height: normal;
  font-weight: bold;
  border-radius: 6px 6px 6px 6px;
}

.select-filter {
  border-width: 0px 0px 1px;
  background: #ffffff;
  padding-right: 24px;
  height: initial;
  padding: 8px;
  border: none;
}

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
  width: 100px;
  border-width: 0px 0px 1px;
  background: #ffffff;
  border-radius: 0px;
  padding-right: 24px;
  padding-right: 0px !important;
  color: rgb(72, 72, 72) !important;
  height: initial;
  font-size: 14px;
  line-height: 1.6;
}

.MuiInput-underline:after {
  border-bottom: 0px !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #c00 !important;
}

.add-more-filters {
  border-radius: 6px !important;
  background-color: hsl(0, 0%, 96%);
}

.add-more-filters:before {
  border-bottom: 0px !important;
}

#mui-component-select-filters {
  border-bottom: 0px;
}

.MuiListSubheader-sticky {
  position: relative !important;
}

.filter-sub-headers {
  font-size: 20px !important;
  color: #c00 !important;
  border-bottom: 1px solid black !important;
  margin-left: 15px;
  margin-right: 15px !important;
  padding-left: 0px !important;
}

.filter-checkbox {
  color: #cc0000 !important;
  padding: 9px !important;
}

.read-more-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
}
.extra-content {
  color: cornflowerblue;
}
.companyDescriptionLink {
  margin: 0 !important;
  display: contents;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #c00 !important;
}

.tabField {
  font-size: 17px;
  line-height: 1.268;
  font-family: "Graphik, sans-serif";
}

.tabsHeading {
  letter-spacing: "wide";
  font-size: 21px;
  color: #cc0000;
  margin-top: 8px;
  background-color: lightgrey;
  justify-content: center;
  display: flex;
  font-family: "Graphik, sans-serif";
}

.container {
  display: flex;
  flex-direction: column;
  height: 35%;
  justify-content: space-between;
  padding: 25px;
}

.text-input-material {
  height: 40px;
  color: black !important;
}

@media screen and (max-width: 1320px) {
  .search-bar {
    width: 700px;
    margin-right: 25px;
  }
}

@media screen and (min-width: 1320px) and (max-width: 1620px) {
  .search-bar {
    width: 930px;
    margin-right: 25px;
  }
}

@media screen and (min-width: 1620px) and (max-width: 1920px) {
  .search-bar {
    width: 1120px;
    margin-right: 25px;
  }
}

@media screen and (min-width: 1920px) {
  .search-bar {
    width: 1150px;
    margin-right: 25px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1600px) {
  .grid-container {
    width: 77% !important;
  }
}

@media screen and (min-width: 1920px) {
  .grid-container {
    width: 100%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1600px) {
  .bottom-div {
    border-bottom: 1px solid #d9d9d9;
    margin-top: 9px;
    width: auto;
    margin-right: 30px;
  }
}

@media screen and (min-width: 1920px) {
  .bottom-div {
    border-bottom: 1px solid #d9d9d9;
    margin-top: 9px;
    width: 265px;
    margin-right: 30px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1600px) {
  .selected-filters {
    width: 130px;
  }
}

@media screen and (min-width: 1920px) {
  .selected-filters {
    width: 125px;
  }
}

.announcement-field {
  font-size: 17px !important;
  line-height: 1.268 !important;
  font-family: "Graphik, sans-serif";
  letter-spacing: 0.12px;
}

.ant-tree-checkbox {
  display: none;
}

.ant-tree-icon__customize {
  margin-right: 10px;
}