
.welcome {
    color: #666666;
    letter-spacing: 0.15px;
    text-align: center;
    white-space: normal;
}

.screenTitle {
    text-align: center;
    white-space: normal;
    letter-spacing: 0.15px;
}

.screenText {
    letter-spacing: 0.15px;
    text-align: center;
}

