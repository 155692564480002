.tableStyle {
    color: black !important;
}
  
.typographyStyle {
    padding-top: 5px;
    padding-bottom: 5px;
    letter-spacing: 0.15px;
}
  
.padding4 {
    padding: 4px;
}
  
.padding10 {
    padding-top: 10px;
}

.luceneTabSelected {
    width: 225px;
    font-size: 14px;
    text-transform: uppercase !important;
    color: black !important;
    font-weight: 500 !important;
}
  
.luceneTabUnselected {
    width: 225px;
    font-size: 14px;
    text-transform: uppercase !important;
    color: grey !important;
    font-weight: 100 !important;
}

.tableHeader {
    font-weight: bold !important;
    color: black !important;
}

.dialogBox {
    max-height: 80%;
    height: 75%;
}

.companyLabel {
    color: #666666;
    font-size: 12px !important;
    letter-spacing: 0.00938em;
    line-height: 1.6 !important;
    margin-bottom: 4px !important;
}