.caseCodeForm{
    padding-top: 2vh;
}

.caseCodeFormRow {
    padding-top: 0.8vh;
    padding-bottom: 0.8vh;
    display: flex;
    justify-content: space-between;
}

.caseCodeDivider {
    border-bottom: solid rgb(221, 221, 221) 2px;
    margin: 1vh;

}
